export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
        "clusters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kubernetes Cluster"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
        "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resources Package"])},
        "mirrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OS Mirrors"])},
        "kuboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuboard Integration"])}
      },
      "zh": {
        "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["展开"])},
        "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收起"])},
        "clusters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集群管理"])},
        "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系统设置"])},
        "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资源包管理"])},
        "mirrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OS 软件源"])},
        "kuboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuboard 集成"])}
      }
    }
  })
}

export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "currentLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Login User"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
        "loadingFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed in loadinng"])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Name"])},
        "currentCluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Cluster"])},
        "logoutAction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Please go to ", _interpolate(_named("type")), " to logout"])},
        "stayLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel logout, stay login"])}
      },
      "zh": {
        "currentLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前登录用户"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退 出"])},
        "loadingFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载失败"])},
        "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
        "currentCluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前集群"])},
        "logoutAction": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["请到 ", _interpolate(_named("type")), " 退出登录"])},
        "stayLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消退出，保持登录状态"])}
      }
    }
  })
}
